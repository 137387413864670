export const initResultsCountObserver = () => {
  const filterCountWrapper = document.querySelector('#js-answersVerticalResultsCount');
  if (filterCountWrapper) {
    const config = { attributes: true, childList: true, subtree: true };
    const callback = function(mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation.addedNodes) {
          for (const node of mutation.addedNodes) {
            if (node.classList?.contains('yxt-VerticalResultsCount')) {
              const verticalResultsCountDiv = document.querySelector('.yxt-VerticalResultsCount');
              const resultsCountSpan = document.querySelector('.yxt-VerticalResultsCount-total');
              const vertical = document.querySelector('.Answers-verticalHeader');
              let resultsLimit = 100;

              if (vertical) {
                if (vertical.classList.contains('Answers-verticalHeader-HelpArticles')) {
                  resultsLimit = 50;
                }
              }

              if (resultsCountSpan) {
                const resultsCount = Number(resultsCountSpan.innerHTML);

                if (resultsCount > resultsLimit) {
                  if (verticalResultsCountDiv) {
                    const ariaLabel = verticalResultsCountDiv.getAttribute('aria-label').replace(resultsCountSpan.innerHTML, resultsLimit.toString());
                    verticalResultsCountDiv.setAttribute('aria-label', ariaLabel);
                  }

                  const pageNumbers = document.querySelectorAll('.js-yxt-Pagination-link');
                  for (const pageNumber of pageNumbers) {
                    if (Number(pageNumber.innerHTML) > resultsLimit/10) {
                      pageNumber.style.display = 'none';
                    }
                  }

                  resultsCountSpan.innerHTML = resultsLimit.toString();
                  const resultsEndSpan = document.querySelector('.yxt-VerticalResultsCount-end');
                  if (resultsEndSpan) {
                    const resultsEnd = Number(resultsEndSpan.innerHTML);
                    if (resultsEnd == resultsLimit) {
                      const resultsNext = document.querySelector('.js-yxt-Pagination-next');
                      if (resultsNext) {
                          resultsNext.style.display = 'none';
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    const observer = new MutationObserver(callback);
    observer.observe(filterCountWrapper, config);
  };
}

export const locationBiasListener = () => {
  const locationBiasButton = document.querySelector('.js-locationBias-update-location');
  if (locationBiasButton) {
    locationBiasButton.addEventListener('keydown', function(e) {
      if (e.keyCode == '32') {
        e.preventDefault();
      }
    })
  }
}