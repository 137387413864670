/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
import escape from 'escape-html';

export function highlightFieldNew(fieldValue, matchedSubstrings = []) {
  let highlightedString = '';

  // We must first sort the matchedSubstrings by ascending offset. 
  const sortedMatches = matchedSubstrings.slice()
    .sort((match1, match2) => match1.offset - match2.offset);
  
  let processedFieldValueIndex = 0;
  sortedMatches.forEach(match => {
    const { offset, length } = match;
    highlightedString += escape(fieldValue.substring(processedFieldValueIndex, offset))
      + `${escape(fieldValue.substring(offset, offset + length))}`;
    processedFieldValueIndex = offset + length;
  });
  highlightedString += escape(fieldValue.substring(processedFieldValueIndex));
  return highlightedString;
}